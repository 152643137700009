import { useField } from 'formik'
import { FooterItem } from 'src/components'
import { Trans, useTranslation } from 'react-i18next'
import styles from './one-more-step-footer.module.scss'

const OneMoreStepFooter = () => {
  const [{ value }] = useField({ name: 'carCompanies' })
  const { t } = useTranslation()
  const commentKey =
    value.length && !value.includes('uber') && !value.includes('lyft')
      ? 'IDGIsHereForYou'
      : 'toAccessTheBenefits'

  if (value.includes('uber') || value.includes('lyft') || value == '') {
    return (
      <FooterItem
        illustration="Gift"
        title="other.oneMoreStep"
        comment={`other.${commentKey}`}
      />
    )
  } else {
    return (
      <>
        <div className={styles.comment}>
          <Trans
            t={t}
            i18nKey="other.byCompletingTheInformation"
            components={{
              termsOfUseLink: (
                <a
                  href="https://driversguild.org/terms-of-use/"
                  target="_blank"
                />
              ),
              privacyPolicyLink: (
                <a
                  href="https://driversguild.org/privacy-policy/"
                  target="_blank"
                />
              ),
            }}
          />
        </div>
        <FooterItem
          illustration="Gift"
          title="other.oneMoreStep"
          comment={`other.${commentKey}`}
        />
      </>
    )
  }
}

export default OneMoreStepFooter
