import { useTranslation } from 'react-i18next'

import useGetSystemMessages from './hook'

import { ReactComponent as Icon } from './icon.svg'

import styles from './system-messages.module.scss'

const SystemMessages = () => {
  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  const systemMessagesQuery = useGetSystemMessages()

  const systemMessages = systemMessagesQuery.data?.messages ?? []

  if (!systemMessages.length) {
    return null
  }

  const alerts = systemMessages.filter((m) => m.type === 'alert')
  const blockers = systemMessages.filter((m) => m.type === 'blocking_message')

  return (
    <>
      {alerts.length ? (
        <div className={styles.alertsWrapper}>
          {alerts.map((a) => (
            <div key={a.id} className={styles.alertMessage}>
              <Icon />

              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: a.message[currentLanguage] ?? a.message.en,
                }}
              />
            </div>
          ))}
        </div>
      ) : null}

      {blockers.length ? (
        <div className={styles.blockersWrapper}>
          {blockers.map((b) => (
            <div key={b.id} className={styles.blockerMessage}>
              <div className={styles.iconWrapper}>
                <Icon />
              </div>

              <div className={styles.title}>
                {t('systemMessages.blocking.title')}
              </div>

              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: b.message[currentLanguage] ?? b.message.en,
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default SystemMessages
