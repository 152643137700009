import { useTranslation, Trans } from 'react-i18next'

import { FooterItem } from 'src/components'

import styles from './good-job-footer.module.scss'

const GoodJobFooter = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.comment}>
        <Trans
          t={t}
          i18nKey="other.byCompletingTheInformation"
          components={{
            termsOfUseLink: (
              <a
                href="https://driversguild.org/terms-of-use/"
                target="_blank"
              />
            ),
            privacyPolicyLink: (
              <a
                href="https://driversguild.org/privacy-policy/"
                target="_blank"
              />
            ),
          }}
        />
      </div>
      <FooterItem
        illustration="Like"
        title="other.goodJob"
        comment="other.submitAndGetBenefits"
      />
    </>
  )
}

export default GoodJobFooter
