export const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'https://api.dev.driversguild.net'

export const REDIRECT_DELIVERY_WEB =
  process.env.REDIRECT_DELIVERY_WEB ||
  'https://driversguild.org/delivery-workers/'

export const QUERY_CLIENT_DEFAULT_OPTIONS = {
  queries: {
    retry: false,
    referchOnWindowFocus: false,
  },
}
