export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'ਈ - ਮੇਲ',
    firstName: 'ਪਹਿਲਾ ਨਾਂ',
    lastName: 'ਆਖਰੀ ਨਾਂਮ',
    carCompanies: 'ਤੁਸੀਂ ਕਿਹੜੀਆਂ ਐਪਲੀਕੇਸ਼ਨਾਂ ਲਈ ਕੰਮ ਕਰਦੇ ਹੋ?',
    gender: 'ਲਿੰਗ',
    phoneNumber: 'ਫੋਨ ਨੰਬਰ',
    dateOfBirth: 'ਜਨਮ ਤਾਰੀਖ',
    streetAddress: 'ਸੜਕ ਦਾ ਪਤਾ',
    apartment: 'ਅਪਾਰਟਮੈਂਟ, ਯੂਨਿਟ, ਸੂਟ, ਜਾਂ ਫਰਸ਼ #',
    city: 'ਸ਼ਹਿਰ',
    stateSelectHome: 'ਰਾਜ',
    zipCode: 'ਜ਼ਿਪ ਕੋਡ',
    driversLicenseNumber: 'ਡਰਾਇਵਰ ਦਾ ਲਾਇਸੈਂਸ #',
    driversLicenseNumberСonfirm: 'ਡ੍ਰਾਈਵਰਜ਼ ਲਾਇਸੈਂਸ ਮੁੜ-ਦਾਖਲ ਕਰੋ #',
    driversId: 'ID #',
    driversIdСonfirm: 'ID # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    tlcLicenseNumber: 'TLC #',
    tlcLicenseNumberСonfirm: 'TLC # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    howYouDeliver: 'ਤੁਸੀਂ ਕਿਵੇਂ ਪ੍ਰਦਾਨ ਕਰਦੇ ਹੋ',
    whatStateDoYouDriveIn: 'ਤੁਸੀਂ ਕਿਸ ਰਾਜ ਵਿੱਚ ਗੱਡੀ ਚਲਾਉਂਦੇ ਹੋ',
  },
  placeholder: {
    email: 'ਆਪਣਾ ਈ - ਮੇਲ ਭਰੋ',
    firstName: 'ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ',
    lastName: 'ਆਪਣਾ ਆਖਰੀ ਨਾਮ ਦਰਜ ਕਰੋ',
    gender: 'ਲਿੰਗ ਚੁਣੋ',
    phoneNumber: '000-000-0000',
    streetAddress: 'ਆਪਣਾ ਗਲੀ ਦਾ ਪਤਾ ਦਰਜ ਕਰੋ',
    apartment: '# ਦਾਖਲ ਕਰੋ',
    city: 'ਸ਼ਹਿਰ ਵਿੱਚ ਦਾਖਲ ਹੋਵੋ',
    stateSelectHome: 'ਰਾਜ ਚੁਣੋ',
    zipCode: 'ਆਪਣਾ ਜ਼ਿਪ ਕੋਡ ਦਰਜ ਕਰੋ',
    driversLicenseNumber: 'ਆਪਣਾ ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਦਰਜ ਕਰੋ',
    driversLicenseNumberСonfirm: 'ਡ੍ਰਾਈਵਰਜ਼ ਲਾਇਸੈਂਸ ਮੁੜ-ਦਾਖਲ ਕਰੋ #',
    driversId: 'ਆਪਣੀ ID ਦਰਜ ਕਰੋ',
    driversIdСonfirm: 'ID # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    tlcLicenseNumber: 'ਆਪਣਾ TLC # ਦਾਖਲ ਕਰੋ',
    tlcLicenseNumberСonfirm: 'TLC # ਮੁੜ-ਦਾਖਲ ਕਰੋ',
    other: 'ਹੋਰ',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'ਨਰ',
      Female: 'ਔਰਤ',
      'Prefer not to say': 'ਨਾ ਕਹਿਣਾ ਪਸੰਦ ਕਰਦੇ ਹਨ',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'ਕਾਰ', bike: 'ਬਾਈਕ', onFoot: 'ਪੈਦਲ' },
  },
  button: { nextStep: 'ਅਗਲਾ ਕਦਮ', submit: 'ਜਮ੍ਹਾਂ ਕਰੋ' },
  error: {
    required: 'ਹਾਜ਼ਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    email: 'ਈਮੇਲ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    phone: 'ਅਵੈਧ ਫ਼ੋਨ ਨੰਬਰ',
    date: 'ਅਵੈਧ ਮਿਤੀ',
    driverAge: {
      dateOfBirth: 'ਤੁਹਾਡੀ ਉਮਰ 16 ਤੋਂ 100 ਸਾਲ ਦੇ ਵਿਚਕਾਰ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ',
    },
    name: 'ਇੱਕ ਵੈਧ ਨਾਮ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    streetAddress: 'ਇੱਕ ਵੈਧ ਗਲੀ ਦਾ ਪਤਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    city: 'ਇੱਕ ਵੈਧ ਸ਼ਹਿਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    zipCode: 'ਇੱਕ ਵੈਧ ਜ਼ਿਪ ਕੋਡ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    driverLicense: 'ਇੱਕ ਵੈਧ ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    atLeast1: 'ਘੱਟੋ-ਘੱਟ ਇੱਕ ਵਿਕਲਪ ਚੁਣੋ',
    equalTo: {
      driversLicenseNumberСonfirm: 'ਡਰਾਈਵਰ ਲਾਇਸੰਸ ਮੇਲ ਖਾਂਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
      driversIdСonfirm: 'ਆਈਡੀ ਮੇਲ ਖਾਂਦੀ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ',
      tlcLicenseNumberСonfirm: 'TLC ਮੇਲ ਖਾਂਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ',
    },
  },
  other: {
    step: 'ਕਦਮ',
    oneMoreStep: 'ਇੱਕ ਹੋਰ ਕਦਮ',
    toAccessTheBenefits: 'ਲਾਭਾਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ',
    IDGIsHereForYou:
      'IDG ਤੁਹਾਡੇ ਲਈ ਇੱਥੇ ਹੈ! ਐਪ ਵਰਕਰ ਅਧਿਕਾਰਾਂ ਲਈ ਲੜਨ ਲਈ ਸਾਡੇ ਨਾਲ ਜੁੜੋ!\nਨੋਟ ਕਰੋ ਕਿ ਡਿਲੀਵਰੀ ਕਰਮਚਾਰੀਆਂ ਲਈ ਅਜੇ ਕੋਈ ਲਾਭ ਨਹੀਂ ਹਨ।',
    byCompletingTheInformation:
      'ਉਪਰੋਕਤ ਜਾਣਕਾਰੀ ਨੂੰ ਪੂਰਾ ਕਰਕੇ ਅਤੇ "ਸਬਮਿਟ" \'ਤੇ ਕਲਿੱਕ ਕਰਕੇ, ਤੁਸੀਂ ਪੁਸ਼ਟੀ ਕਰਦੇ ਹੋ ਕਿ ਤੁਸੀਂ ਸਾਡੀਆਂ <termsOfUseLink> ਵਰਤੋਂ ਦੀਆਂ ਸ਼ਰਤਾਂ </termsOfUseLink> ਨੂੰ ਪੜ੍ਹਿਆ, ਸਮਝ ਲਿਆ ਅਤੇ ਸਹਿਮਤ ਹੋ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਤੁਸੀਂ ਸਹਿਮਤੀ ਦਿੱਤੀ ਹੈ ਕਿ ਅਸੀਂ ਸਾਡੀ <privacyPolicyLink>ਗੋਪਨੀਯਤਾ ਨੀਤੀ</privacyPolicyLink> ਵਿੱਚ ਦਰਸਾਏ ਅਨੁਸਾਰ ਤੁਹਾਡੀ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਅਤੇ ਖੁਲਾਸਾ ਕਰ ਸਕਦੇ ਹਾਂ।',
    goodJob: 'ਅੱਛਾ ਕੰਮ!',
    submitAndGetBenefits: 'ਲਾਭਾਂ ਤੱਕ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰੋ!',
    congratulations: 'ਵਧਾਈਆਂ',
    weWillBeInTouchSoon: 'ਅਸੀਂ ਜਲਦੀ ਹੀ ਸੰਪਰਕ ਵਿੱਚ ਰਹਾਂਗੇ!',
    successThanks: 'ਸਾਡੇ ਨਾਲ ਜੁੜਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ!',
    successMessage:
      'ਸਾਨੂੰ ਤੁਹਾਡੀ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਹੋਈ ਹੈ। ਅਸੀਂ ਜਲਦੀ ਹੀ ਸੰਪਰਕ ਵਿੱਚ ਰਹਾਂਗੇ!',
    successThanksSubtitle: "ਅਸੀਂ ਤੁਹਾਡੀ ਅਰਜ਼ੀ 'ਤੇ ਪ੍ਰਕਿਰਿਆ ਕਰ ਰਹੇ ਹਾਂ",

    successMessageCT:
      'ਤੁਸੀਂ ਡਰਾਈਵਰਾਂ ਦੇ ਕਨੈਕਟੀਕਟ ਭਾਈਚਾਰੇ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਵੱਲ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਿਆ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਸਮਰਥਨ ਨਾਲ ਹੈ ਕਿ ਅਸੀਂ ਬਿਹਤਰ ਕੰਮ ਦੀਆਂ ਸਥਿਤੀਆਂ ਦੇ ਨਾਲ ਇੱਕ ਨਿਰਪੱਖ ਉਦਯੋਗ ਲਈ ਲੜਨਾ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹਾਂ। <br><br>' +
      'ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੀ ਨਵੀਂ IDG ਮੈਂਬਰਸ਼ਿਪ ਬਾਰੇ ਹੋਰ ਵੇਰਵਿਆਂ ਦੇ ਨਾਲ ਇੱਕ ਈਮੇਲ ਭੇਜਾਂਗੇ, ਅਤੇ ਤੁਸੀਂ ਸਾਡੇ ਭਾਈਚਾਰੇ ਵਿੱਚ ਕਿਵੇਂ ਸਰਗਰਮ ਰਹਿ ਸਕਦੇ ਹੋ। <br> <br>' +
      'ਜੇਕਰ ਤੁਸੀਂ ਅਗਲੇ ਕੁਝ ਦਿਨਾਂ ਵਿੱਚ ਇਸਨੂੰ ਆਪਣੇ ਇਨਬਾਕਸ ਵਿੱਚ ਨਹੀਂ ਦੇਖਦੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਸਪੈਮ ਫੋਲਡਰ ਦੀ ਜਾਂਚ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਓ। <br><br>' +
      "ਤੁਰੰਤ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ ਜਾਂ ਕੋਈ ਸਵਾਲ ਹਨ? ਸਾਨੂੰ <ctPhone>833-240-7063</ctPhone>, M-F 8am - 8pm ET 'ਤੇ ਕਾਲ ਕਰੋ ਜਾਂ <ctLink>ct.driversguild.org.</ctLink> 'ਤੇ ਸਾਡੇ ਦਫਤਰ ਦੇ ਸਮੇਂ ਨੂੰ ਲੱਭੋ।",

    successMessageNJ:
      'ਤੁਸੀਂ ਡਰਾਈਵਰਾਂ ਦੇ ਨਿਊ ਜਰਸੀ ਭਾਈਚਾਰੇ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਵੱਲ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਿਆ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਸਮਰਥਨ ਨਾਲ ਹੈ ਕਿ ਅਸੀਂ ਬਿਹਤਰ ਕੰਮ ਦੀਆਂ ਸਥਿਤੀਆਂ ਦੇ ਨਾਲ ਇੱਕ ਨਿਰਪੱਖ ਉਦਯੋਗ ਲਈ ਲੜਨਾ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹਾਂ। <br><br>' +
      'ਕਿਰਪਾ ਕਰਕੇ ਨੋਟ ਕਰੋ ਕਿ ਤੁਹਾਨੂੰ NJ ਵਿੱਚ ਇੱਕ ਸਰਗਰਮ ਡਰਾਈਵਰ ਹੋਣ ਦੀ ਪੁਸ਼ਟੀ ਕਰਕੇ ਸਾਨੂੰ ਤੁਹਾਡੀ ਮੈਂਬਰਸ਼ਿਪ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਦੀ ਲੋੜ ਹੋਵੇਗੀ। <br><br>' +
      'ਜੇਕਰ ਤੁਸੀਂ ਅਗਲੇ ਕੁਝ ਦਿਨਾਂ ਵਿੱਚ ਆਪਣੇ ਇਨਬਾਕਸ ਵਿੱਚ ਕੋਈ ਈਮੇਲ ਨਹੀਂ ਦੇਖਦੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਸਪੈਮ ਫੋਲਡਰ ਦੀ ਜਾਂਚ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਓ। <br><br>' +
      "ਤੁਰੰਤ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ ਜਾਂ ਕੋਈ ਸਵਾਲ ਹਨ? ਸਾਨੂੰ <njPhone>1-844-335-1264</njPhone>, M-F 8am - 8pm ET 'ਤੇ ਕਾਲ ਕਰੋ ਜਾਂ <njLink>nj.driversguild.org.</njLink> 'ਤੇ ਸਾਡੇ ਦਫਤਰ ਦੇ ਸਮੇਂ ਅਤੇ ਆਉਣ ਵਾਲੇ ਸਮਾਗਮਾਂ ਨੂੰ ਲੱਭੋ।",

    successMessageMA:
      'ਤੁਸੀਂ ਡਰਾਈਵਰਾਂ ਦੇ ਮੈਸੇਚਿਉਸੇਟਸ ਭਾਈਚਾਰੇ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਵੱਲ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਿਆ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਸਮਰਥਨ ਨਾਲ ਹੈ ਕਿ ਅਸੀਂ ਬਿਹਤਰ ਕੰਮ ਦੀਆਂ ਸਥਿਤੀਆਂ ਦੇ ਨਾਲ ਇੱਕ ਨਿਰਪੱਖ ਉਦਯੋਗ ਲਈ ਲੜਨਾ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹਾਂ। <br><br></br>' +
      'ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੀ ਨਵੀਂ IDG ਮੈਂਬਰਸ਼ਿਪ ਬਾਰੇ ਹੋਰ ਵੇਰਵਿਆਂ ਦੇ ਨਾਲ ਇੱਕ ਈਮੇਲ ਭੇਜਾਂਗੇ, ਅਤੇ ਤੁਸੀਂ ਸਾਡੇ ਭਾਈਚਾਰੇ ਵਿੱਚ ਕਿਵੇਂ ਸਰਗਰਮ ਰਹਿ ਸਕਦੇ ਹੋ। <br><br>' +
      'ਜੇਕਰ ਤੁਸੀਂ ਅਗਲੇ ਕੁਝ ਦਿਨਾਂ ਵਿੱਚ ਇਸਨੂੰ ਆਪਣੇ ਇਨਬਾਕਸ ਵਿੱਚ ਨਹੀਂ ਦੇਖਦੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਸਪੈਮ ਫੋਲਡਰ ਦੀ ਜਾਂਚ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਓ। <br><br>' +
      "ਤੁਰੰਤ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ ਜਾਂ ਕੋਈ ਸਵਾਲ ਹਨ? ਸਾਨੂੰ <maPhone>1-833-240-7062</maPhone> M-F 8am - 8pm ET 'ਤੇ ਕਾਲ ਕਰੋ।",

    successMessageIL:
      'ਤੁਸੀਂ ਡਰਾਈਵਰਾਂ ਦੇ ਇਲੀਨੋਇਸ ਭਾਈਚਾਰੇ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਵੱਲ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਿਆ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਸਮਰਥਨ ਨਾਲ ਹੈ ਕਿ ਅਸੀਂ ਬਿਹਤਰ ਕੰਮ ਦੀਆਂ ਸਥਿਤੀਆਂ ਦੇ ਨਾਲ ਇੱਕ ਨਿਰਪੱਖ ਉਦਯੋਗ ਲਈ ਲੜਨਾ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹਾਂ। <br> <br>' +
      'ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੀ ਨਵੀਂ IDG ਮੈਂਬਰਸ਼ਿਪ ਬਾਰੇ ਹੋਰ ਵੇਰਵਿਆਂ ਦੇ ਨਾਲ ਇੱਕ ਈਮੇਲ ਭੇਜਾਂਗੇ, ਅਤੇ ਤੁਸੀਂ ਸਾਡੇ ਭਾਈਚਾਰੇ ਵਿੱਚ ਕਿਵੇਂ ਸਰਗਰਮ ਰਹਿ ਸਕਦੇ ਹੋ। <br><br>' +
      'ਜੇਕਰ ਤੁਸੀਂ ਅਗਲੇ ਕੁਝ ਦਿਨਾਂ ਵਿੱਚ ਇਸਨੂੰ ਆਪਣੇ ਇਨਬਾਕਸ ਵਿੱਚ ਨਹੀਂ ਦੇਖਦੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਸਪੈਮ ਫੋਲਡਰ ਦੀ ਜਾਂਚ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਓ। <br><br>' +
      "ਸਵਾਲ? ਸਾਨੂੰ <ilPhone>1-833-240-7064</ilPhone> 'ਤੇ ਕਾਲ ਕਰੋ ਜਾਂ <ilLink>il.driversguild.org.</ilLink> 'ਤੇ ਸਾਡੇ ਦਫ਼ਤਰੀ ਸਮੇਂ ਅਤੇ ਆਉਣ ਵਾਲੇ ਸਮਾਗਮਾਂ ਦੀ ਜਾਂਚ ਕਰੋ। <br><br>",

    successMessageFL:
      'ਤੁਸੀਂ ਡਰਾਈਵਰਾਂ ਦੇ ਫਲੋਰੀਡਾ ਭਾਈਚਾਰੇ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਵੱਲ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਿਆ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਸਮਰਥਨ ਨਾਲ ਹੈ ਕਿ ਅਸੀਂ ਬਿਹਤਰ ਕੰਮ ਦੀਆਂ ਸਥਿਤੀਆਂ ਦੇ ਨਾਲ ਇੱਕ ਨਿਰਪੱਖ ਉਦਯੋਗ ਲਈ ਲੜਨਾ ਜਾਰੀ ਰੱਖ ਸਕਦੇ ਹਾਂ। <br><br>' +
      'ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੀ ਨਵੀਂ IDG ਮੈਂਬਰਸ਼ਿਪ ਬਾਰੇ ਹੋਰ ਵੇਰਵਿਆਂ ਦੇ ਨਾਲ ਇੱਕ ਈਮੇਲ ਭੇਜਾਂਗੇ, ਅਤੇ ਤੁਸੀਂ ਸਾਡੇ ਭਾਈਚਾਰੇ ਵਿੱਚ ਕਿਵੇਂ ਸਰਗਰਮ ਰਹਿ ਸਕਦੇ ਹੋ। <br><br>' +
      'ਜੇਕਰ ਤੁਸੀਂ ਅਗਲੇ ਕੁਝ ਦਿਨਾਂ ਵਿੱਚ ਇਸਨੂੰ ਆਪਣੇ ਇਨਬਾਕਸ ਵਿੱਚ ਨਹੀਂ ਦੇਖਦੇ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਸਪੈਮ ਫੋਲਡਰ ਦੀ ਜਾਂਚ ਕਰਨਾ ਯਕੀਨੀ ਬਣਾਓ। <br><br>' +
      "ਸਵਾਲ? ਸਾਨੂੰ <flPhone>1-833-240-7064</flPhone> 'ਤੇ ਕਾਲ ਕਰੋ ਜਾਂ <flLink>fl.driversguild.org</flLink> 'ਤੇ ਸਾਡੇ ਦਫ਼ਤਰੀ ਸਮੇਂ ਅਤੇ ਆਉਣ ਵਾਲੇ ਸਮਾਗਮਾਂ ਦੀ ਜਾਂਚ ਕਰੋ।",

    useBenefits: 'ਆਪਣੇ ਲਾਭਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ',
    contactUs: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    deliveryWorkers: 'ਡਿਲਿਵਰੀ ਵਰਕਰ',
  },
}
