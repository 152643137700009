import dayjs from 'dayjs'
import * as yup from 'yup'

declare module 'yup' {
  interface StringSchema {
    driverAge(msg?: string): this
    date(msg?: string): this
    phone(msg?: string): this
    name(msg?: string): this
    streetAddress(msg?: string): this
    city(msg?: string): this
    zipCode(msg?: string): this
    driverLicense(msg?: string): this
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'driverAge',
  function (message = 'must be between 16 and 100') {
    return this.test('valid-driverAge', message, function (value = '') {
      return value
        ? (() => {
            const yearForChecking = dayjs(value, 'YYYY-mm-DD').year()
            const currentYear = dayjs().year()
            return (
              yearForChecking >= currentYear - 100 &&
              yearForChecking <= currentYear - 16
            )
          })()
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'date',
  function (message = 'must be a valid date') {
    return this.test('valid-date', message, function (value = '') {
      return value
        ? /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(value) &&
            (() => {
              const yearForChecking = dayjs(value, 'YYYY-mm-DD').year()
              const currentYear = dayjs().year()
              return (
                yearForChecking > currentYear - 100 &&
                yearForChecking < currentYear - 10
              )
            })()
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phone',
  function (message = 'must be a valid phone number') {
    return this.test('valid-phone', message, function (value = '') {
      return value
        ? /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
            value,
          )
        : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'name',
  function (message = 'must be a valid name') {
    return this.test('valid-name', message, function (value = '') {
      return value ? /^[a-zA-Z\s\-]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'streetAddress',
  function (message = 'must be a valid street address') {
    return this.test('valid-streetAddress', message, function (value = '') {
      return value ? /^[a-zA-Z0-9\s\-\&\.]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'city',
  function (message = 'must be a valid city') {
    return this.test('valid-city', message, function (value = '') {
      return value ? /^[a-zA-Z\s\-]*$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'zipCode',
  function (message = 'must be a valid zip code') {
    return this.test('valid-zipCode', message, function (value = '') {
      return value ? /^\d{5}(-\d{4})?$/.test(value) : true
    })
  },
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'driverLicense',
  function (message = 'must be a valid driver license') {
    return this.test('valid-driverLicense', message, function (value = '') {
      return value ? /^[a-zA-Z0-9]*$/.test(value) : true
    })
  },
)

export default yup
