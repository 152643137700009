import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Label, Icons } from 'src/components'

import styles from './tabs.module.scss'

interface TabsProps {
  stepsCount: number
  currentStepIndex: number
}

const Tabs = (props: TabsProps) => {
  const { stepsCount, currentStepIndex } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      {Array.from(Array(stepsCount).keys()).map((s, i) => (
        <div
          key={i}
          className={cx(
            styles.step,
            i === currentStepIndex && styles.active,
            i < currentStepIndex && styles.passed,
          )}
          //onClick={() => setCurrentStep(i)}
        >
          <Label className={styles.stepNumber}>
            {i < currentStepIndex ? <Icons.Check /> : <span>{i + 1}</span>}
          </Label>
          <Label className={styles.stepLabel} size="bigger">
            {t('other.step')}
          </Label>
        </div>
      ))}
    </div>
  )
}

export default Tabs
