export default {
  label: {
    preferredLanguage: 'Seleccionar Idioma',
    email: 'Correo electrónico',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    carCompanies: '¿Para qué aplicación(es) trabaja?',
    gender: 'Género',
    phoneNumber: 'Número de teléfono',
    dateOfBirth: 'Fecha de nacimiento',
    streetAddress: 'Dirección',
    apartment: 'Apt., o # de piso',
    city: 'Ciudad',
    stateSelectHome: 'Estado',
    zipCode: 'Código postal',
    driversLicenseNumber: 'Número de su Licencia de Conducir',
    driversLicenseNumberСonfirm:
      'Vuelva a ingresar el número de su Licencia de Conducir',
    driversId: 'ID',
    driversIdСonfirm: 'Vuelva a ingresar el ID',
    tlcLicenseNumber: 'Número de TLC',
    tlcLicenseNumberСonfirm: 'Vuelva a ingresar el número de TLC',
    howYouDeliver: '¿Cómo entregas?',
    whatStateDoYouDriveIn: '¿En qué estado conduces?',
  },
  placeholder: {
    email: 'Ingrese su Correo Electrónico',
    firstName: 'Ingrese su Nombre',
    lastName: 'Ingrese su Apellido',
    gender: 'Seleccione su Género',
    phoneNumber: '000-000-0000',
    streetAddress: 'Ingrese su Dirección de Domicilio',
    apartment: 'Ingrese el número de su apartamento',
    city: 'Ingrese su Ciudad',
    stateSelectHome: 'Seleccione su Estado',
    zipCode: 'Ingrese su Código Postal',
    driversLicenseNumber: 'Ingrese el número de su Licencia de Conducir',
    driversLicenseNumberСonfirm:
      'Vuelva a ingresar el número de su Licencia de Conducir',
    driversId: 'Ingrese su ID',
    driversIdСonfirm: 'Vuelva a ingresar el ID',
    tlcLicenseNumber: 'Ingrese su número de TLC',
    tlcLicenseNumberСonfirm: 'Vuelva a ingresar el número de TLC',
    other: 'Otro',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'Masculino',
      Female: 'Femenino',
      'Prefer not to say': 'Prefiero no decirlo',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'Coche', bike: 'Bicicleta', onFoot: 'A pie' },
  },
  button: { nextStep: 'Próximo paso', submit: 'Enviar' },
  error: {
    required: 'Debe estar presente',
    email: 'Debe ser un correo electrónico',
    phone: 'Número de teléfono inválido',
    date: 'Fecha inválida',
    driverAge: { dateOfBirth: 'Debes tener entre 16 y 100 años' },
    name: 'Debe ser un nombre válido',
    streetAddress: 'Debe ser una dirección válida',
    city: 'Debe ser una ciudad válida',
    zipCode: 'Debe ser un código postal válido',
    driverLicense: 'Debe ser una licencia de conducir válida',
    atLeast1: 'Seleccione al menos una opción',
    equalTo: {
      driversLicenseNumberСonfirm:
        'El número de la licencia de conducir debe coincidir',
      driversIdСonfirm: 'El ID debe coincidir',
      tlcLicenseNumberСonfirm: 'El número de TLC debe coincidir',
    },
  },
  other: {
    step: 'Paso',
    oneMoreStep: 'Un paso más',
    toAccessTheBenefits: 'para acceder a los beneficios',
    IDGIsHereForYou:
      '¡IDG está aquí para usted! ¡Únase a nosotros para luchar por los derechos de los trabajadores de aplicaciones!\nTenga en cuenta que todavía no hay beneficios para los trabajadores de entrega.',
    byCompletingTheInformation:
      'Al completar la información anterior y hacer click en "Enviar", usted confirma que ha leído, entendido y aceptado nuestros <termsOfUseLink> Términos de Uso</termsOfUseLink>. Además, ha aceptado que podemos usar y divulgar su información como se establece en nuestra <privacyPolicyLink>Política de privacidad</privacyPolicyLink>',
    goodJob: '¡Buen trabajo!',
    submitAndGetBenefits:
      '¡Envíe el formulario para obtener acceso a los beneficios!',
    congratulations: 'Felicidades',
    weWillBeInTouchSoon: '¡Nos veremos pronto!',
    successThanks: 'Gracias por unirte a nosotros!',
    successMessage: 'Hemos recibido tu información. ¡Nos veremos pronto!',
    successThanksSubtitle: 'Estamos procesando tu solicitud',

    successMessageCT:
      'Ha dado el primer paso para unirse a la comunidad de conductores de Connecticut. Es con su apoyo que podemos seguir luchando por una industria justa con mejores condiciones laborales. <br><br>' +
      'Le enviaremos un correo electrónico con más detalles sobre su nueva membresía de IDG y cómo puede mantenerse activo en nuestra comunidad. <br><br>' +
      'Si no lo ve en su bandeja de entrada en los próximos días, asegúrese de revisar su carpeta de correo no deseado. <br><br>' +
      '¿Quiere obtener acceso de inmediato o tiene preguntas? Llámenos al <ctPhone>833-240-7063</ctPhone>, de lunes a viernes, de 8 a. m. a 8 p. m., hora del Este, o consulte nuestro horario de atención en <ctLink>ct.driversguild.org.</ctLink>.',

    successMessageNJ:
      'Ha dado el primer paso para unirse a la comunidad de conductores de Nueva Jersey. Es con su apoyo que podemos seguir luchando por una industria justa con mejores condiciones laborales. <br><br>' +
      'Tenga en cuenta que necesitaremos verificar su membresía confirmando que es un conductor activo en Nueva Jersey. <br><br>' +
      'Si no ve un correo electrónico en su bandeja de entrada en los próximos días, asegúrese de revisar su carpeta de correo no deseado. <br><br>' +
      '¿Quiere obtener acceso de inmediato o tiene preguntas? Llámenos al <njPhone>1-844-335-1264</njPhone>, de lunes a viernes, de 8 a. m. a 8 p. m., hora del Este, o consulte nuestro horario de atención y próximos eventos en <njLink>nj.driversguild.org.</njLink>.',

    successMessageMA:
      'Ha dado el primer paso para unirse a la comunidad de conductores de Massachusetts. Es con su apoyo que podemos seguir luchando por una industria justa con mejores condiciones laborales. <br><br>' +
      'Le enviaremos un correo electrónico con más detalles sobre su nueva membresía de IDG y cómo puede mantenerse activo en nuestra comunidad. <br><br>' +
      'Si no lo ve en su bandeja de entrada en los próximos días, asegúrese de revisar su carpeta de correo no deseado. <br><br>' +
      '¿Quiere obtener acceso de inmediato o tiene preguntas? Llámenos al <maPhone>1- 833-240-7062</maPhone>, de lunes a viernes, de 8 a. m. a 8 p. m., hora del Este.',

    successMessageIL:
      'Ha dado el primer paso para unirse a la comunidad de conductores de Illinois. Es con su apoyo que podemos seguir luchando por una industria justa con mejores condiciones laborales. <br><br>' +
      'Le enviaremos un correo electrónico con más detalles sobre su nueva membresía de IDG y cómo puede mantenerse activo en nuestra comunidad.<br><br>' +
      'Si no lo ve en su bandeja de entrada en los próximos días, asegúrese de revisar su carpeta de correo no deseado. <br><br>' +
      "¿Preguntas? Llámenos al <ilPhone>1-833-240-7064</ilPhone> o consulte nuestro horario de oficina y próximos eventos en <ilLink>il.driversguild.org.</ilLink>'.",

    successMessageFL:
      'Ha dado el primer paso para unirse a la comunidad de conductores de Florida. Es con su apoyo que podemos seguir luchando por una industria justa con mejores condiciones laborales. <br> <br>' +
      'Le enviaremos un correo electrónico con más detalles sobre su nueva membresía de IDG y cómo puede mantenerse activo en nuestra comunidad. <br><br>' +
      'Si no lo ve en su bandeja de entrada en los próximos días, asegúrese de revisar su carpeta de correo no deseado.<br><br>' +
      '¿Preguntas? Llámenos al <flPhone>1-833-240-7064</flPhone> o consulte nuestro horario de oficina y próximos eventos en <flLink>fl.driversguild.org</flLink>.',
    useBenefits: 'Usa tus Beneficios',
    contactUs: 'Contacta con nosotros',
    deliveryWorkers: 'Trabajadores de entrega',
  },
  systemMessages: {
    blocking: {
      title: 'Temporalmente No Disponible',
    },
  },
}
