export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'Эл. адрес',
    firstName: 'Имя',
    lastName: 'Фамилия',
    carCompanies: 'В каких приложениях вы работаете?',
    gender: 'Пол',
    phoneNumber: 'Номер телефона',
    dateOfBirth: 'Дата рождения',
    streetAddress: 'Адрес улицы',
    apartment: 'Номер квартиры, блока или этажа',
    city: 'Город',
    stateSelectHome: 'Состояние',
    zipCode: 'Почтовый индекс',
    driversLicenseNumber: 'Водительские права #',
    driversLicenseNumberСonfirm:
      'Повторно введите номер водительского удостоверения',
    driversId: 'ID #',
    driversIdСonfirm: 'Повторно введите номер идентификатора',
    tlcLicenseNumber: 'TLC #',
    tlcLicenseNumberСonfirm: 'Повторно введите TLC #',
    howYouDeliver: 'Как вы доставляете',
    whatStateDoYouDriveIn: 'В каком штате США вы ездите',
  },
  placeholder: {
    email: 'Введите адрес электронной почты',
    firstName: 'Введите ваше имя',
    lastName: 'Введите вашу фамилию',
    gender: 'Выберите пол',
    phoneNumber: '000-000-0000',
    streetAddress: 'Введите свой почтовый адрес',
    apartment: 'Введите номер',
    city: 'Войти в город',
    stateSelectHome: 'Выберите штат',
    zipCode: 'Введите свой почтовый индекс',
    driversLicenseNumber: 'Введите свои водительские права',
    driversLicenseNumberСonfirm: 'Повторно введите водительское удостоверение',
    driversId: 'Введите свой идентификатор',
    driversIdСonfirm: 'Повторно введите номер идентификатора',
    tlcLicenseNumber: 'Введите номер вашего TLC',
    tlcLicenseNumberСonfirm: 'Повторно введите TLC #',
    other: 'Другое',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'Мужской',
      Female: 'Женский',
      'Prefer not to say': 'Предпочитаю не говорить',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'Автомобиль', bike: 'Велосипед', onFoot: 'Пешком' },
  },
  button: { nextStep: 'Следующий шаг', submit: 'Представлять на рассмотрение' },
  error: {
    required: 'Должен присутствовать',
    email: 'Должен быть адрес электронной почты',
    phone: 'Неправильный номер телефона',
    date: 'Недействительная дата',
    driverAge: { dateOfBirth: 'Вам должно быть от 16 до 100 лет' },
    name: 'Должно быть допустимое имя',
    streetAddress: 'Должен быть действительный почтовый адрес',
    city: 'Должен быть действительный город',
    zipCode: 'Должен быть действительный почтовый индекс',
    driverLicense: 'Должно быть действующее водительское удостоверение',
    atLeast1: 'Выберите хотя бы один вариант',
    equalTo: {
      driversLicenseNumberСonfirm:
        'Водительское удостоверение должно соответствовать',
      driversIdСonfirm: 'ID должен совпадать',
      tlcLicenseNumberСonfirm: 'TLC должен совпадать',
    },
  },
  other: {
    step: 'Шаг',
    oneMoreStep: 'Еще один шаг',
    toAccessTheBenefits: 'чтобы получить доступ к преимуществам',
    IDGIsHereForYou:
      'IDG здесь для вас! Присоединяйтесь к нам, чтобы бороться за права работников приложений!\nОтметим, что льгот для курьеров пока нет.',
    byCompletingTheInformation:
      'Заполняя приведенную выше информацию и нажимая «Отправить», вы подтверждаете, что прочитали, поняли и обязуетесь соблюдать наши <termsOfUseLink>Условия использования</termsOfUseLink>. Кроме того, вы соглашаетесь с тем, что мы можем использовать и раскрывать персональную  информацию в соответствии с нашей <privacyPolicyLink>Политикой конфиденциальности</privacyPolicyLink>.',
    goodJob: 'Отличная работа!',
    submitAndGetBenefits:
      'Отправьте форму, чтобы получить доступ к преимуществам!',
    congratulations: 'Поздравления',
    weWillBeInTouchSoon: 'Мы свяжемся с вами в ближайшее время!',
    successThanks: 'Спасибо, что присоединился к нам!',
    successThanksSubtitle: 'Мы обрабатываем вашу заявку',
    successMessageCT:
      'Вы сделали первый шаг к присоединению к сообществу водителей Коннектикута. Именно с вашей поддержкой мы сможем продолжать борьбу за справедливую индустрию с улучшенными условиями труда.<br><br>' +
      'Мы вышлем вам электронное письмо с более подробной информацией о вашем новом членстве в IDG и о том, как вы можете оставаться активными в нашем сообществе.<br><br>' +
      'Если вы не увидите его в своем почтовом ящике в течение следующих нескольких дней, обязательно проверьте папку со спамом. <br><br>' +
      'Хотите получить доступ немедленно или у вас есть вопросы? Позвоните нам по телефону <ctPhone>833-240-7063</ctPhone>, понедельник-пятница с 8:00 до 20:00 по восточноевропейскому времени или узнайте часы работы на сайте <ctLink>ct.driversguild.org</ctLink>.<br><br>',
    successMessageNJ:
      'Вы сделали первый шаг к присоединению к сообществу водителей Нью-Джерси. Именно с вашей поддержкой мы сможем продолжать борьбу за справедливую индустрию с улучшенными условиями труда.<br><br>' +
      'Обратите внимание, что нам нужно будет подтвердить ваше членство, подтвердив, что вы являетесь активным водителем в Нью-Джерси.<br><br>' +
      'Если вы не увидите письмо во входящих в течение следующих нескольких дней, обязательно проверьте папку со спамом. <br><br>' +
      'Хотите получить доступ немедленно или у вас есть вопросы? Позвоните нам по телефону <njPhone>1-844-335-1264</njPhone>, понедельник-пятница с 8:00 до 20:00 по восточноевропейскому времени или узнайте часы работы и предстоящие мероприятия на сайте <njLink>nj.driversguild.org.</njLink>.',

    successMessageMA:
      'Вы сделали первый шаг к присоединению к сообществу водителей Массачусетса. Именно с вашей поддержкой мы сможем продолжать борьбу за справедливую индустрию с улучшенными условиями труда. <br><br>' +
      'Мы вышлем вам электронное письмо с более подробной информацией о вашем новом членстве в IDG и о том, как вы можете оставаться активными в нашем сообществе. <br><br>' +
      'Если вы не увидите его в своем почтовом ящике в течение следующих нескольких дней, обязательно проверьте папку со спамом.<br><br>' +
      'Хотите получить доступ немедленно или у вас есть вопросы? Позвоните нам по телефону <maPhone>1- 833-240-7062</maPhone>, понедельник-пятница с 8:00 до 20:00 по восточноевропейскому времени.<br><br>',

    successMessageIL:
      'Вы сделали первый шаг к присоединению к сообществу водителей Иллинойса. Именно с вашей поддержкой мы сможем продолжать борьбу за справедливую индустрию с улучшенными условиями труда. <br><br>' +
      'Мы вышлем вам электронное письмо с более подробной информацией о вашем новом членстве в IDG и о том, как вы можете оставаться активными в нашем сообществе. <br><br>' +
      'Если вы не увидите его в своем почтовом ящике в течение следующих нескольких дней, обязательно проверьте папку со спамом. <br><br>' +
      'Вопросы? Позвоните нам по телефону <ilPhone>1-833-240-7064</ilPhone> или ознакомьтесь с графиком работы нашего офиса и предстоящими мероприятиями на сайте <ilLink>il.driversguild.org</ilLink>.<br><br>',
    successMessageFL:
      'Вы сделали первый шаг к присоединению к сообществу водителей Флориды. Именно с вашей поддержкой мы сможем продолжать борьбу за справедливую индустрию с улучшенными условиями труда.<br><br>' +
      'Мы вышлем вам электронное письмо с более подробной информацией о вашем новом членстве в IDG и о том, как вы можете оставаться активными в нашем сообществе.<br><br>' +
      'Если вы не увидите его в своем почтовом ящике в течение следующих нескольких дней, обязательно проверьте папку со спамом.<br><br>' +
      'Вопросы? Позвоните нам по телефону <flPhone>1-833-240-7064</flPhone>  или проверьте часы работы нашего офиса и предстоящие мероприятия на сайте <flLink>fl.driversguild.org</flLink>.',

    useBenefits: 'Используйте свои преимущества',
    contactUs: 'Свяжитесь с нами',
    deliveryWorkers: 'Доставщики',
  },
}
