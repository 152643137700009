import { getUrlParamsValues } from 'src/helpers'

export const CONST_VALUES = {
  formVariant: 'COOL_FORM_REACT_V1',
  form: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
  browserDevice: window.navigator.userAgent,
  browserLanguage: window.navigator.language,
  ...getUrlParamsValues(),
}

export const EXCEPTIONS_FOR_INITIAL_LOCAL_STORAGE = ['form_template', 'utm']
