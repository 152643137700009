import { useTranslation } from 'react-i18next'

import { Button } from 'src/components'

import OneMoreStepFooter from './OneMoreStepFooter'
import GoodJobFooter from './GoodJobFooter'

import styles from './footer.module.scss'

const FooterComponent = ({ index }: { index: number }) => {
  switch (index) {
    case 0:
      return <OneMoreStepFooter />
    case 1:
      return <GoodJobFooter />
  }

  return null
}

interface FooterProps {
  loading: boolean
  stepsCount: number
  currentStepIndex: number
  onNext: () => void
}

const Footer = (props: FooterProps) => {
  const { loading, stepsCount, currentStepIndex, onNext } = props

  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <FooterComponent index={currentStepIndex} />

      <Button
        className={styles.button}
        loading={loading}
        iconAfter="Arrow"
        onClick={onNext}
      >
        {t(
          `button.${currentStepIndex + 1 < stepsCount ? 'nextStep' : 'submit'}`,
        )}
      </Button>
    </div>
  )
}

export default Footer
