import { ILeadMutationData, IFieldDependsOn } from 'src/interfaces'

const isEmptyArray = (fieldValue: any, ruleValue: boolean) => {
  if (Array.isArray(fieldValue)) {
    return !(fieldValue as Array<any>).length === ruleValue
  } else {
    return fieldValue == null
  }
}

const included = (fieldValue: any, ruleValue: string[]) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.find((s: string) => ruleValue.includes(s))
  } else {
    return ruleValue.includes(fieldValue)
  }
}

const notIncluded = (fieldValue: any, ruleValue: string[]) => {
  if (!fieldValue) {
    console.log('0')
    return true
  }
  if (Array.isArray(fieldValue)) {
    console.log('1')
    return !fieldValue.find((s: string) => ruleValue.includes(s))
  } else {
    console.log('2', fieldValue, ruleValue)
    return fieldValue !== ruleValue
  }
}

const getDependingCheck = (
  formikValues: ILeadMutationData,
  dependings?: {
    [Key in keyof ILeadMutationData]?: IFieldDependsOn
  },
) => {
  if (!dependings) {
    return true
  }

  return Object.entries(dependings).every(([fieldKey, fieldRules]) => {
    return Object.entries(fieldRules).every(
      ([ruleName, ruleValue]: [name: string, value: any]) => {
        const fieldValue = formikValues[fieldKey as keyof ILeadMutationData]
        console.log('ruleName', ruleName, 'fieldValue', fieldValue)
        switch (ruleName) {
          case 'emptyArray':
            console.log('emptyArray', notIncluded(fieldValue, ruleValue))
            return isEmptyArray(fieldValue, ruleValue)
          case 'included':
            console.log('included', notIncluded(fieldValue, ruleValue))
            return included(fieldValue, ruleValue)
          case 'notIncluded':
            console.log('notIncluded', notIncluded(fieldValue, ruleValue))
            return notIncluded(fieldValue, ruleValue)
        }
      },
    )
  })
}

export default getDependingCheck
