import { useTranslation } from 'react-i18next'

import { Illustrations } from 'src/components'

import styles from './footer-item.module.scss'

interface FooterItemProps {
  illustration: string
  title: string
  isBigTitle?: boolean
  comment: string
}

const FooterItem = (props: FooterItemProps) => {
  const { illustration, title, isBigTitle, comment } = props

  const { t } = useTranslation()

  const IllustrationComponent =
    illustration &&
    (Object.entries(Illustrations)?.find(
      ([key, value]) => key === illustration,
    )?.[1] ??
      null)

  return (
    <div className={styles.wrapper}>
      {IllustrationComponent ? <IllustrationComponent /> : null}

      <div className={styles.text}>
        <div
          className={styles[`basicItalicBold${!isBigTitle ? 'Big' : 'Huge'}`]}
        >
          {t(title)}
        </div>
        <div className={styles.basicComment}>{t(comment)}</div>
      </div>
    </div>
  )
}

export default FooterItem
