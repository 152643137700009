export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'ইমেইল',
    firstName: 'নামের প্রথম অংশ',
    lastName: 'নামের শেষাংশ',
    carCompanies: 'আপনি কোন অ্যাপ্লিকেশনের জন্য কাজ করেন?',
    gender: 'লিঙ্গ',
    phoneNumber: 'ফোন নম্বর',
    dateOfBirth: 'জন্ম তারিখ',
    streetAddress: 'রাস্তার ঠিকানা',
    apartment: 'অ্যাপার্ট, ইউনিট, স্যুট বা মেঝে #',
    city: 'শহর',
    stateSelectHome: 'রাষ্ট্র',
    zipCode: 'জিপ কোড',
    driversLicenseNumber: 'চালকের লাইসেন্স #',
    driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স পুনরায় প্রবেশ করান #',
    driversId: 'ID #',
    driversIdСonfirm: 'আইডি # পুনরায় লিখুন',
    tlcLicenseNumber: 'TLC #',
    tlcLicenseNumberСonfirm: 'TLC # পুনরায় প্রবেশ করুন',
    howYouDeliver: 'আপনি কিভাবে বিতরণ',
    whatStateDoYouDriveIn: 'আপনি কোন রাজ্যে গাড়ি চালান',
  },
  placeholder: {
    email: 'তুমার ইমেইল প্রবেশ করাও',
    firstName: 'আপনার নাম প্রবেশ করুন',
    lastName: 'আপনার শেষ নাম লিখুন',
    gender: 'লিংগ নির্বাচন',
    phoneNumber: '000-000-0000',
    streetAddress: 'আপনার রাস্তার ঠিকানা লিখুন',
    apartment: '# লিখুন',
    city: 'শহরে প্রবেশ করুন',
    stateSelectHome: 'রাজ্য নির্বাচন কর',
    zipCode: 'আপনার জিপ কোড প্রদান করুন',
    driversLicenseNumber: 'আপনার ড্রাইভিং লাইসেন্স লিখুন',
    driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স পুনরায় প্রবেশ করান #',
    driversId: 'আপনার আইডি লিখুন',
    driversIdСonfirm: 'আইডি # পুনরায় লিখুন',
    tlcLicenseNumber: 'আপনার TLC # লিখুন',
    tlcLicenseNumberСonfirm: 'TLC # পুনরায় প্রবেশ করুন',
    other: 'অন্যান্য',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'পুরুষ',
      Female: 'মহিলা',
      'Prefer not to say': 'না বলতে পছন্দ করেন',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'গাড়ি', bike: 'বাইক', onFoot: 'হেঁটে' },
  },
  button: { nextStep: 'পরবর্তী পর্ব', submit: 'জমা দিন' },
  error: {
    required: 'উপস্থিত থাকতে হবে',
    email: 'ইমেইল হতে হবে',
    phone: 'অবৈধ ফোন নম্বর',
    date: 'অবৈধ তারিখ',
    driverAge: { dateOfBirth: 'আপনার বয়স 16 থেকে 100 বছরের মধ্যে হতে হবে' },
    name: 'একটি বৈধ নাম হতে হবে',
    streetAddress: 'একটি বৈধ রাস্তার ঠিকানা হতে হবে',
    city: 'একটি বৈধ শহর হতে হবে',
    zipCode: 'একটি বৈধ জিপ কোড হতে হবে',
    driverLicense: 'একটি বৈধ ড্রাইভার লাইসেন্স হতে হবে',
    atLeast1: 'অন্তত একটি বিকল্প নির্বাচন করুন',
    equalTo: {
      driversLicenseNumberСonfirm: 'ড্রাইভিং লাইসেন্স অবশ্যই মিলবে',
      driversIdСonfirm: 'আইডি অবশ্যই মিলবে',
      tlcLicenseNumberСonfirm: 'TLC অবশ্যই মিলবে',
    },
  },
  other: {
    step: 'ধাপ',
    oneMoreStep: 'আর এক ধাপ',
    toAccessTheBenefits: 'সুবিধা অ্যাক্সেস করতে',
    IDGIsHereForYou:
      'IDG আপনার জন্য এখানে! অ্যাপ কর্মীদের অধিকারের জন্য লড়াই করতে আমাদের সাথে যোগ দিন!\nউল্লেখ্য যে ডেলিভারি কর্মীদের জন্য এখনো কোনো সুবিধা নেই।',
    byCompletingTheInformation:
      'উপরের তথ্যটি সম্পূর্ণ করে এবং "জমা দিন" এ ক্লিক করে আপনি নিশ্চিত করেন যে আপনি আমাদের <termsOfUseLink> ব্যবহারের শর্তাবলী</termsOfUseLink> পড়েছেন, বুঝেছেন এবং সম্মত হয়েছেন। এছাড়াও, আপনি সম্মত হয়েছেন যে আমরা আমাদের <privacyPolicyLink>গোপনীয়তা নীতি</privacyPolicyLink>-এ বর্ণিত আপনার তথ্য ব্যবহার এবং প্রকাশ করতে পারি।',
    goodJob: 'সাবাশ!',
    submitAndGetBenefits: 'সুবিধা পেতে ফর্ম জমা দিন!',
    congratulations: 'অভিনন্দন',
    weWillBeInTouchSoon: 'আমরা খুব শীঘ্রই যোগাযোগ করা হবে!',
    successThanks: 'ধন্যবাদ আমাদের সাথে যোগদান করার জন্য!',
    successMessage: 'আমরা আপনার তথ্য পেয়েছি. আমরা খুব শীঘ্রই যোগাযোগ করা হবে!',
    successThanksSubtitle: 'আমরা আপনার আবেদন প্রক্রিয়া করছি',
    successMessageCT:
      'আপনি ড্রাইভারদের কানেকটিকাট সম্প্রদায়ে যোগদানের দিকে প্রথম পদক্ষেপ নিয়েছেন। এটি আপনার সমর্থনে যে আমরা উন্নত কাজের পরিবেশ সহ একটি ন্যায্য শিল্পের জন্য লড়াই চালিয়ে যেতে পারি।\n' +
      'আমরা আপনাকে আপনার নতুন IDG সদস্যতা সম্পর্কে আরও বিশদ বিবরণ সহ একটি ইমেল পাঠাব এবং আপনি কীভাবে আমাদের সম্প্রদায়ে সক্রিয় থাকতে পারেন। <br> <br>' +
      'আপনি যদি আগামী কয়েক দিনের মধ্যে আপনার ইনবক্সে এটি দেখতে না পান, অনুগ্রহ করে আপনার স্প্যাম ফোল্ডারটি পরীক্ষা করে দেখুন৷<br><br>' +
      'অবিলম্বে অ্যাক্সেস পেতে চান বা প্রশ্ন আছে? আমাদের কল করুন <ctPhone>833-240-7063</ctPhone> M-F সকাল 8am - 8pm ET অথবা <ctLink>ct.driversguild.org.</ctLink>-এ আমাদের অফিসের সময় খুঁজুন।',

    successMessageNJ:
      'আপনি ড্রাইভারদের নিউ জার্সি সম্প্রদায়ে যোগদানের দিকে প্রথম পদক্ষেপ নিয়েছেন। এটি আপনার সমর্থনে যে আমরা উন্নত কাজের পরিবেশ সহ একটি ন্যায্য শিল্পের জন্য লড়াই চালিয়ে যেতে পারি। অনুগ্রহ করে মনে রাখবেন আপনি NJ-এ একজন সক্রিয় ড্রাইভার তা নিশ্চিত করে আমাদের আপনার সদস্যপদ যাচাই করতে হবে। <br><br>' +
      'আপনি যদি আগামী কয়েক দিনের মধ্যে আপনার ইনবক্সে একটি ইমেল দেখতে না পান, অনুগ্রহ করে আপনার স্প্যাম ফোল্ডারটি পরীক্ষা করে দেখুন৷ <br><br>' +
      'অবিলম্বে অ্যাক্সেস পেতে চান বা প্রশ্ন আছে? আমাদের কল করুন <njPhone>1-844-335-1264</njPhone>, M-F সকাল 8am - 8pm ET অথবা <njLink>nj.driversguild.org.</njLink>-এ আমাদের অফিসের সময় এবং আসন্ন ইভেন্টগুলি খুঁজুন৷',
    successMessageMA:
      'আপনি ড্রাইভারদের ম্যাসাচুসেটস সম্প্রদায়ে যোগদানের দিকে প্রথম পদক্ষেপ নিয়েছেন। এটি আপনার সমর্থনে যে আমরা উন্নত কাজের পরিবেশ সহ একটি ন্যায্য শিল্পের জন্য লড়াই চালিয়ে যেতে পারি।\n' +
      'আমরা আপনাকে আপনার নতুন IDG সদস্যতা সম্পর্কে আরও বিশদ বিবরণ সহ একটি ইমেল পাঠাব এবং আপনি কীভাবে আমাদের সম্প্রদায়ে সক্রিয় থাকতে পারেন। <br> <br>' +
      'আপনি যদি আগামী কয়েক দিনের মধ্যে আপনার ইনবক্সে এটি দেখতে না পান, অনুগ্রহ করে আপনার স্প্যাম ফোল্ডারটি পরীক্ষা করে দেখুন৷ <br> <br>' +
      'অবিলম্বে অ্যাক্সেস পেতে চান বা প্রশ্ন আছে? আমাদের কল করুন <maPhone>1-833-240-7062</maPhone>, M-F সকাল 8am - 8pm ET.',
    successMessageIL:
      'আপনি ড্রাইভারদের ইলিনয় সম্প্রদায়ে যোগদানের দিকে প্রথম পদক্ষেপ নিয়েছেন। এটি আপনার সমর্থনে যে আমরা উন্নত কাজের পরিবেশ সহ একটি ন্যায্য শিল্পের জন্য লড়াই চালিয়ে যেতে পারি। <br><br> আমরা আপনাকে আপনার নতুন IDG সদস্যতা সম্পর্কে আরও বিশদ বিবরণ সহ একটি ইমেল পাঠাব এবং আপনি কীভাবে আমাদের সম্প্রদায়ে সক্রিয় থাকতে পারেন৷ <br><br>' +
      ' আপনি যদি আগামী কয়েক দিনের মধ্যে আপনার ইনবক্সে এটি দেখতে না পান, অনুগ্রহ করে আপনার স্প্যাম ফোল্ডারটি পরীক্ষা করে দেখুন৷ <br> <br> প্রশ্ন? আমাদের <ilPhone>1-833-240-7064</ilPhone> এ কল করুন অথবা <ilLink>il.driversguild.org</ilLink>-এ আমাদের অফিসের সময় এবং আসন্ন ইভেন্টগুলি দেখুন।',

    successMessageFL:
      'আপনি ড্রাইভারদের ফ্লোরিডা সম্প্রদায়ে যোগদানের দিকে প্রথম পদক্ষেপ নিয়েছেন। এটি আপনার সমর্থনে যে আমরা উন্নত কাজের পরিবেশ সহ একটি ন্যায্য শিল্পের জন্য লড়াই চালিয়ে যেতে পারি। <br><br> আমরা আপনাকে আপনার নতুন IDG সদস্যতা সম্পর্কে আরও বিশদ বিবরণ সহ একটি ইমেল পাঠাব এবং আপনি কীভাবে আমাদের সম্প্রদায়ে সক্রিয় থাকতে পারেন৷ <br><br> আপনি যদি আগামী কয়েক দিনের মধ্যে আপনার ইনবক্সে এটি দেখতে না পান, অনুগ্রহ করে আপনার স্প্যাম ফোল্ডারটি পরীক্ষা করে দেখুন৷ <br><br> প্রশ্ন? আমাদের <flPhone>1-833-240-7064</flPhone> এ কল করুন অথবা <flLink>fl.driversguild.org</flLink>-এ আমাদের অফিসের সময় এবং আসন্ন ইভেন্টগুলি দেখুন',

    useBenefits: 'আপনার সুবিধা ব্যবহার করুন',
    contactUs: 'যোগাযোগ করুন',
    deliveryWorkers: 'ডেলিভারি কর্মী',
  },
}
