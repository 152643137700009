export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'ईमेल',
    firstName: 'पहला नाम',
    lastName: 'उपनाम',
    carCompanies: 'आप किस ऐप (ऐप्स) के लिए काम करते हैं?',
    gender: 'लिंग',
    phoneNumber: 'फ़ोन नंबर',
    dateOfBirth: 'जन्म की तारीख',
    streetAddress: 'गली का पता',
    apartment: 'उपयुक्त, इकाई, सूट, या मंजिल #',
    city: 'शहर',
    stateSelectHome: 'राज्य',
    zipCode: 'पिन कोड',
    driversLicenseNumber: 'ड्राइवर का लाइसेंस #',
    driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस फिर से दर्ज करें #',
    driversId: 'ID #',
    driversIdСonfirm: 'आईडी फिर से दर्ज करें #',
    tlcLicenseNumber: 'TLC #',
    tlcLicenseNumberСonfirm: 'टीएलसी # फिर से दर्ज करें',
    howYouDeliver: 'आप कैसे पहुंचाते हैं',
    whatStateDoYouDriveIn: 'आप किस राज्य में ड्राइव करते हैं',
  },
  placeholder: {
    email: 'अपना ईमेल दर्ज करें',
    firstName: 'अपना नाम दर्ज करें',
    lastName: 'अपना अंतिम नाम दर्ज करें',
    gender: 'लिंग चुनें',
    phoneNumber: '000-000-0000',
    streetAddress: 'अपनी गली का पता दर्ज करें',
    apartment: 'प्रवेश करना #',
    city: 'शहर दर्ज करें',
    stateSelectHome: 'राज्य चुनें',
    zipCode: 'अपना ज़िप कोड दर्ज करें',
    driversLicenseNumber: 'अपना ड्राइविंग लाइसेंस दर्ज करें',
    driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस फिर से दर्ज करें #',
    driversId: 'अपनी आईडी दर्ज करें',
    driversIdСonfirm: 'आईडी फिर से दर्ज करें #',
    tlcLicenseNumber: 'अपना टीएलसी दर्ज करें #',
    tlcLicenseNumberСonfirm: 'टीएलसी # फिर से दर्ज करें',
    other: 'अन्य',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'पुरुष',
      Female: 'मादा',
      'Prefer not to say': 'चुप रहना पसंद करूंगा',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'गाड़ी', bike: 'साइकिल', onFoot: 'पैरों पर' },
  },
  button: { nextStep: 'अगला कदम', submit: 'प्रस्तुत करना' },
  error: {
    required: 'अनिवार्य उपस्थिति',
    email: 'ईमेल होना चाहिए',
    phone: 'अवैध फोन नंबर',
    date: 'अमान्य तिथि',
    driverAge: { dateOfBirth: 'आपकी आयु 16 से 100 वर्ष के बीच होनी चाहिए' },
    name: 'एक मान्य नाम होना चाहिए',
    streetAddress: 'एक मान्य गली का पता होना चाहिए',
    city: 'एक वैध शहर होना चाहिए',
    zipCode: 'एक मान्य ज़िप कोड होना चाहिए',
    driverLicense: 'वैध ड्राइवर लाइसेंस होना चाहिए',
    atLeast1: 'कम से कम एक विकल्प चुनें',
    equalTo: {
      driversLicenseNumberСonfirm: 'ड्राइवर का लाइसेंस मेल खाना चाहिए',
      driversIdСonfirm: 'आईडी का मिलान होना चाहिए',
      tlcLicenseNumberСonfirm: 'टीएलसी का मिलान होना चाहिए',
    },
  },
  other: {
    step: 'कदम',
    oneMoreStep: 'एक और क़दम',
    toAccessTheBenefits: 'लाभों तक पहुँचने के लिए',
    IDGIsHereForYou:
      'आईडीजी यहाँ आपके लिए है! ऐप वर्कर अधिकारों के लिए लड़ने के लिए हमसे जुड़ें!\nध्यान दें कि डिलीवरी श्रमिकों के लिए अभी तक कोई लाभ नहीं है।',
    byCompletingTheInformation:
      'ऊपर दी गई जानकारी को पूरा करके और "सबमिट" पर क्लिक करके, आप पुष्टि करते हैं कि आपने हमारी <termsOfUseLink> उपयोग की शर्तें</termsOfUseLink> को पढ़, समझ लिया है और उनसे सहमत हैं। इसके अलावा, आप सहमत हैं कि हम अपनी <privacyPolicyLink>गोपनीयता नीति</privacyPolicyLink> में बताए अनुसार आपकी जानकारी का उपयोग और खुलासा कर सकते हैं।',
    goodJob: 'सेवा की शर्तें।',
    submitAndGetBenefits: 'गोपनीयता नीति।',
    congratulations: 'बधाई हो',
    weWillBeInTouchSoon: 'हम जल्द ही संपर्क में आएँगे!',
    successThanks: 'हमसे जुडने के लिए तुम्हारा शुक्रिया!',
    successMessage: 'हमें आपकी जानकारी मिल गई है। हम जल्द ही संपर्क में आएँगे!',
    successThanksSubtitle: 'हम आपके आवेदन पर कार्रवाई कर रहे हैं',
    successMessageCT:
      'आपने ड्राइवरों के कनेक्टिकट समुदाय में शामिल होने की दिशा में पहला कदम उठाया है। यह आपके समर्थन से ही है कि हम बेहतर कामकाजी परिस्थितियों के साथ एक निष्पक्ष उद्योग के लिए लड़ना जारी रख सकते हैं। <br><br>' +
      'हम आपको आपकी नई आईडीजी सदस्यता के बारे में अधिक विवरण के साथ एक ईमेल भेजेंगे, और आप हमारे समुदाय में कैसे सक्रिय रह सकते हैं। <br><br>' +
      'यदि आप इसे अगले कुछ दिनों में अपने इनबॉक्स में नहीं देखते हैं, तो कृपया अपने स्पैम फ़ोल्डर की जांच करना सुनिश्चित करें। <br><br>' +
      'क्या आप तुरंत पहुँच प्राप्त करना चाहते हैं या आपके कोई प्रश्न हैं? हमें <ctPhone>833-240-7063</ctPhone> एम-एफ सुबह 8 बजे से रात 8 बजे ईटी पर कॉल करें या <ctLink>ct.driversguild.org.</ctLink> पर हमारे कार्यालय समय का पता लगाएं। <br><br>',

    successMessageNJ:
      'आपने न्यू जर्सी के ड्राइवर समुदाय में शामिल होने की दिशा में पहला कदम उठाया है। यह आपके समर्थन से ही है कि हम बेहतर कामकाजी परिस्थितियों के साथ एक निष्पक्ष उद्योग के लिए लड़ना जारी रख सकते हैं। <br><br>' +
      'कृपया ध्यान दें कि हमें यह पुष्टि करके आपकी सदस्यता सत्यापित करने की आवश्यकता होगी कि आप एनजे में एक सक्रिय ड्राइवर हैं। <br> <br>' +
      'यदि आपको अगले कुछ दिनों में अपने इनबॉक्स में कोई ईमेल नहीं दिखाई देता है, तो कृपया अपने स्पैम फ़ोल्डर की जांच करना सुनिश्चित करें। <br><br>' +
      'क्या आप तुरंत पहुँच प्राप्त करना चाहते हैं या आपके कोई प्रश्न हैं? हमें <njPhone>1-844-335-1264</njPhone>, एम-एफ सुबह 8 बजे से रात 8 बजे ईटी पर कॉल करें या हमारे कार्यालय समय और आगामी कार्यक्रम <njLink>nj.driversguild.org.</njLink> पर देखें।',

    successMessageMA:
      'आपने मैसाचुसेट्स ड्राइवरों के समुदाय में शामिल होने की दिशा में पहला कदम उठाया है। यह आपके समर्थन से ही है कि हम बेहतर कामकाजी परिस्थितियों के साथ एक निष्पक्ष उद्योग के लिए लड़ना जारी रख सकते हैं। <br> <br>' +
      'हम आपको आपकी नई आईडीजी सदस्यता के बारे में अधिक विवरण के साथ एक ईमेल भेजेंगे, और आप हमारे समुदाय में कैसे सक्रिय रह सकते हैं। <br><br>' +
      'यदि आप इसे अगले कुछ दिनों में अपने इनबॉक्स में नहीं देखते हैं, तो कृपया अपने स्पैम फ़ोल्डर की जांच करना सुनिश्चित करें। <br><br>' +
      'क्या आप तुरंत पहुँच प्राप्त करना चाहते हैं या आपके कोई प्रश्न हैं? हमें <maPhone>1-833-240-7062</maPhone> एम-एफ सुबह 8 बजे से रात 8 बजे ईटी पर कॉल करें।',

    successMessageIL:
      'आपने इलिनोइस ड्राइवर समुदाय में शामिल होने की दिशा में पहला कदम उठाया है। यह आपके समर्थन से ही है कि हम बेहतर कामकाजी परिस्थितियों के साथ एक निष्पक्ष उद्योग के लिए लड़ना जारी रख सकते हैं। <br><br>' +
      'हम आपको आपकी नई आईडीजी सदस्यता के बारे में अधिक विवरण के साथ एक ईमेल भेजेंगे, और आप हमारे समुदाय में कैसे सक्रिय रह सकते हैं। <br><br>' +
      'यदि आप इसे अगले कुछ दिनों में अपने इनबॉक्स में नहीं देखते हैं, तो कृपया अपने स्पैम फ़ोल्डर की जांच करना सुनिश्चित करें। <br><br>' +
      'प्रशन? हमें <ilPhone>1-833-240-7064</ilPhone> पर कॉल करें या <ilLink>il.driversguild.org.</ilLink> पर हमारे कार्यालय समय और आगामी कार्यक्रम देखें।',

    successMessageFL:
      'आपने फ़्लोरिडा ड्राइवर समुदाय में शामिल होने की दिशा में पहला कदम उठाया है। यह आपके समर्थन से ही है कि हम बेहतर कामकाजी परिस्थितियों के साथ एक निष्पक्ष उद्योग के लिए लड़ना जारी रख सकते हैं। <br><br>' +
      'हम आपको आपकी नई आईडीजी सदस्यता के बारे में अधिक विवरण के साथ एक ईमेल भेजेंगे, और आप हमारे समुदाय में कैसे सक्रिय रह सकते हैं। <br><br>' +
      'यदि आप इसे अगले कुछ दिनों में अपने इनबॉक्स में नहीं देखते हैं, तो कृपया अपने स्पैम फ़ोल्डर की जांच करना सुनिश्चित करें। <br><br>' +
      'प्रशन? हमें <flPhone>1-833-240-7064</flPhone>  पर कॉल करें या <flLink>fl.driversguild.org</flLink> पर हमारे कार्यालय समय और आगामी कार्यक्रम देखें।',

    useBenefits: 'अपने लाभ का प्रयोग करें',
    contactUs: 'संपर्क करें',
    deliveryWorkers: 'डिलीवरी कर्मचारी',
  },
}
