export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'البريد الإلكتروني',
    firstName: 'الاسم الاول',
    lastName: 'الكنية',
    carCompanies: 'ما هي التطبيقات التي تعمل بها؟',
    gender: 'جنس',
    phoneNumber: 'رقم الهاتف',
    dateOfBirth: 'تاريخ الولادة',
    streetAddress: 'عنوان الشارع',
    apartment: 'شقة ، وحدة ، بدلة ، أو طابق #',
    city: 'مدينة',
    stateSelectHome: 'حالة',
    zipCode: 'الرمز البريدي',
    driversLicenseNumber: 'رخصة السائق #',
    driversLicenseNumberСonfirm: 'إعادة إدخال رخصة القيادة #',
    driversId: 'ID',
    driversIdСonfirm: 'إعادة إدخال المعرف #',
    tlcLicenseNumber: 'TLC',
    tlcLicenseNumberСonfirm: 'أعد إدخال TLC #',
    howYouDeliver: 'كيف تقدم',
    whatStateDoYouDriveIn: 'ما الولاية التي تقود فيها',
  },
  placeholder: {
    email: 'أدخل بريدك الإلكتروني',
    firstName: 'أدخل أسمك',
    lastName: 'أدخل اسمك الأخير',
    gender: 'حدد نوع الجنس',
    phoneNumber: '000-000-0000',
    streetAddress: 'أدخل عنوان الشارع الخاص بك',
    apartment: 'يدخل #',
    city: 'أدخل المدينة',
    stateSelectHome: 'اختر ولايه',
    zipCode: 'أدخل رمزك البريدي',
    driversLicenseNumber: 'أدخل رخصة القيادة الخاصة بك',
    driversLicenseNumberСonfirm: 'إعادة إدخال رخصة القيادة #',
    driversId: 'أدخل المعرف',
    driversIdСonfirm: 'إعادة إدخال المعرف #',
    tlcLicenseNumber: 'أدخل رقم TLC الخاص بك',
    tlcLicenseNumberСonfirm: 'أعد إدخال TLC #',
    other: 'آخر',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'ذكر',
      Female: 'أنثى',
      'Prefer not to say': 'افضل عدم القول',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: {
      car: 'جمل',
      bike: 'دراجة هوائية',
      onFoot: 'سيرا على الاقدام',
    },
  },
  button: { nextStep: 'الخطوة التالية', submit: 'يُقدِّم' },
  error: {
    required: 'يجب أن يكون حاضر',
    email: 'يجب أن يكون البريد الإلكتروني',
    phone: 'رقم الهاتف غير صحيح',
    date: 'تاريخ غير صالح',
    driverAge: { dateOfBirth: 'يجب أن يكون عمرك بين 16 و 100 عام' },
    name: 'يجب أن يكون اسمًا صالحًا',
    streetAddress: 'يجب أن يكون عنوان شارع صالحًا',
    city: 'يجب أن تكون مدينة صالحة',
    zipCode: 'يجب أن يكون رمزًا بريديًا صالحًا',
    driverLicense: 'يجب أن تكون رخصة قيادة صالحة',
    atLeast1: 'حدد خيارًا واحدًا على الأقل',
    equalTo: {
      driversLicenseNumberСonfirm: 'يجب أن تتطابق رخصة القيادة',
      driversIdСonfirm: 'يجب أن يتطابق المعرف',
      tlcLicenseNumberСonfirm: 'يجب أن تتطابق TLC',
    },
  },
  other: {
    step: 'خطوة',
    oneMoreStep: 'خطوة أخرى أيضا',
    toAccessTheBenefits: 'للوصول إلى الفوائد',
    IDGIsHereForYou:
      'IDG هنا من أجلك! انضم إلينا للقتال من أجل حقوق عامل التطبيق!\nلاحظ أنه لا توجد فوائد لعمال التوصيل حتى الآن.',
    byCompletingTheInformation:
      'من خلال استكمال المعلومات الواردة أعلاه والنقر فوق "إرسال" ، فإنك تؤكد أنك قد قرأت وفهمت ووافقت على <termsOfUseLink> شروط الاستخدام </termsOfUseLink>. بالإضافة إلى ذلك ، لقد وافقت على أنه يجوز لنا استخدام معلوماتك والكشف عنها على النحو المنصوص عليه في <privacyPolicyLink> سياسة الخصوصية </ privacyPolicyLink>',
    goodJob: 'شروط الخدمة.',
    submitAndGetBenefits: 'سياسة الخصوصية.',
    congratulations: 'تهانينا',
    weWillBeInTouchSoon: 'سنكون على اتصال قريبا!',
    successThanks: 'شكرا لانضمامك إلينا!',
    successThanksSubtitle: 'نحن نقوم بمعالجة طلبك',
    successMessageCT:
      'لقد اتخذت الخطوة الأولى نحو الانضمام إلى مجتمع السائقين في ولاية كونيتيكت. بدعمكم يمكننا مواصلة النضال من أجل صناعة عادلة مع تحسين ظروف العمل.\n' +
      'سنرسل إليك بريدًا إلكترونيًا يتضمن مزيدًا من التفاصيل حول عضويتك الجديدة في IDG، وكيف يمكنك البقاء نشطًا في مجتمعنا.\n' +
      '\n' +
      'إذا لم تجدها في صندوق الوارد الخاص بك خلال الأيام القليلة القادمة، فيرجى التأكد من التحقق من مجلد الرسائل غير المرغوب فيها.\n' +
      '\n' +
      'هل تريد الوصول فورًا أو لديك أسئلة؟ اتصل بنا على<ctPhone>833-240-7063</ctPhone>، من الاثنين إلى الجمعة من 8 صباحًا إلى 8 مساءً بالتوقيت الشرقي أو ابحث عن ساعات عملنا على <ctLink>ct.driversguild.org.</ctLink>.',

    successMessageNJ:
      'لقد اتخذت الخطوة الأولى نحو الانضمام إلى مجتمع السائقين في نيوجيرسي. بدعمكم يمكننا مواصلة النضال من أجل صناعة عادلة مع تحسين ظروف العمل. يرجى ملاحظة أننا سنحتاج إلى التحقق من عضويتك من خلال التأكد من أنك سائق نشط في نيوجيرسي.\n' +
      '\n' +
      'إذا لم تشاهد رسالة بريد إلكتروني في صندوق الوارد الخاص بك خلال الأيام القليلة القادمة، فيرجى التأكد من التحقق من مجلد الرسائل غير المرغوب فيها.\n' +
      '\n' +
      'هل تريد الوصول فورًا أو لديك أسئلة؟ اتصل بنا على <njPhone> 1-844-335-1264</njPhone>  ، من الاثنين إلى الجمعة من 8 صباحًا إلى 8 مساءً بالتوقيت الشرقي أو ابحث عن ساعات عملنا والأحداث القادم على<njLink>nj.driversguild.org</njLink> .',
    successMessageMA:
      'لقد اتخذت الخطوة الأولى نحو الانضمام إلى مجتمع السائقين في ماساتشوستس. بدعمكم يمكننا مواصلة النضال من أجل صناعة عادلة مع تحسين ظروف العمل.\n' +
      'سنرسل إليك بريدًا إلكترونيًا يتضمن مزيدًا من التفاصيل حول عضويتك الجديدة في IDG، وكيف يمكنك البقاء نشطًا في مجتمعنا.\n' +
      '\n' +
      'إذا لم تجدها في صندوق الوارد الخاص بك خلال الأيام القليلة القادمة، فيرجى التأكد من التحقق من مجلد الرسائل غير المرغوب فيها.\n' +
      '\n' +
      'هل تريد الوصول فورًا أو لديك أسئلة؟ اتصل بنا على <maPhone>1-833-240-7062</maPhone>، من الاثنين إلى الجمعة من 8 صباحًا إلى 8 مساءً بالتوقيت الشرقي.',

    successMessageIL:
      'لقد اتخذت الخطوة الأولى نحو الانضمام إلى مجتمع السائقين في إلينوي. بدعمكم يمكننا مواصلة النضال من أجل صناعة عادلة مع تحسين ظروف العمل.\n' +
      'سنرسل إليك بريدًا إلكترونيًا يتضمن مزيدًا من التفاصيل حول عضويتك الجديدة في IDG، وكيف يمكنك البقاء نشطًا في مجتمعنا.\n' +
      'إذا لم تجدها في صندوق الوارد الخاص بك خلال الأيام القليلة القادمة، فيرجى التأكد من التحقق من مجلد الرسائل غير المرغوب فيها.\n' +
      'أسئلة؟ اتصل بنا على <ilPhone>1-833-240-7064</ilPhone> أو تحقق من ساعات عملنا والأحداث القادمة على <ilLink>il.driversguild.org</ilLink>.',
    successMessageFL:
      'لقد اتخذت الخطوة الأولى نحو الانضمام إلى مجتمع السائقين في فلوريدا. بدعمكم يمكننا مواصلة النضال من أجل صناعة عادلة مع تحسين ظروف العمل.\n' +
      'سنرسل إليك بريدًا إلكترونيًا يتضمن مزيدًا من التفاصيل حول عضويتك الجديدة في IDG، وكيف يمكنك البقاء نشطًا في مجتمعنا.\n' +
      'إذا لم تجدها في بريدك الوارد خلال الأيام القليلة القادمة، فيرجى التأكد من التحقق من مجلد الرسائل غير المرغوب فيها.\n' +
      'أسئلة؟ اتصل بنا على <flPhone>1-833-240-7064</flPhone> أو تحقق من ساعات عملنا والأحداث القادمة على <flLink>fl.driversguild.org</flLink>.',
    useBenefits: 'استخدم الفوائد الخاصة بك',
    contactUs: 'اتصل بنا',
    deliveryWorkers: 'عمال التوصيل',
  },
}
