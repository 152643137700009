export { default as getDependingCheck } from './getDependingCheck'

export const getUrlParamsValues = () => ({
  utm: new URLSearchParams(document.location.search).get('utm_source') || '',
  form_template: (() => {
    const result = []

    const urlFormTemplate = new URLSearchParams(document.location.search).get(
      'form_template',
    )
    if (urlFormTemplate) {
      return urlFormTemplate
    }

    const urlContract = new URLSearchParams(document.location.search).get(
      'contract',
    )
    if (urlContract) {
      if (urlContract === '00000101') {
        return 'ce5ad6af-dc00-451e-9c3a-633e6a29cab7'
      }
      if (urlContract === '00000102') {
        return '7a528a54-ec14-4c89-8718-5dcb85f8d20a'
      }
      if (urlContract === '00000103') {
        return '74f0c7f8-2afe-40df-b588-1fa62b5dcdb8'
      }
      if (urlContract === '00000104') {
        return 'b2e70f1c-01b8-439f-adc1-31f1f5c0a47a'
      }
      if (urlContract === '00000105') {
        return '3978ce97-7967-456b-9189-7f1f2fb0f324'
      }
      if (urlContract === '00000109') {
        return '29533d43-7dc1-4cb6-a744-df30265c75e9'
      }
    }

    return null
  })(),
})
