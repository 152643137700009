import { Trans, useTranslation } from 'react-i18next'
import { Button, FooterItem } from 'src/components'
import { Letter } from 'src/components/Illustrations/Illustrations'
import cx from 'classnames'
import { useField, useFormikContext } from 'formik'

import styles from './success-block.module.scss'
import { getUrlParamsValues } from 'src/helpers'
import { useEffect } from 'react'
import { typeOfDeliveryApp } from 'src/App'
import { useState } from 'react'
import { CONST_VALUES, REDIRECT_DELIVERY_WEB } from 'src/config'

const SuccessBlock = () => {
  const { t } = useTranslation()
  const { form_template } = getUrlParamsValues()
  const { values }: any = useFormikContext()
  const [isDeliveryApp, setIsDeliveryApp] = useState<boolean>(false)

  const typeApp = typeOfDeliveryApp(values?.carCompanies)

  useEffect(() => {
    if (typeApp === 'Delivery') {
      setIsDeliveryApp(true)
    }
  }, [values])

  const handleContactUs = () => {
    if (form_template?.includes('ce5ad6af-dc00-451e-9c3a-633e6a29cab7')) {
      window.location.href = 'https://ny.driversguild.org/contact-us/'
      return
    }
    if (form_template?.includes('7a528a54-ec14-4c89-8718-5dcb85f8d20a')) {
      window.location.href = 'https://nj.driversguild.org/contact-us/'
      return
    }
    if (form_template?.includes('74f0c7f8-2afe-40df-b588-1fa62b5dcdb8')) {
      window.location.href = 'https://ma.driversguild.org/contact-us/'
      return
    }
    if (form_template?.includes('b2e70f1c-01b8-439f-adc1-31f1f5c0a47a')) {
      window.location.href = 'https://ct.driversguild.org/contact-us/'
      return
    }
    if (form_template?.includes('3978ce97-7967-456b-9189-7f1f2fb0f324')) {
      window.location.href = 'https://il.driversguild.org/contact-us/'
      return
    }
    window.location.href = 'https://driversguild.org'
    return
  }

  const handleOtherButton = () => {
    if (typeApp === 'Delivery') {
      window.location.href = REDIRECT_DELIVERY_WEB
      return
    }
    if (typeApp === 'Rider' || typeApp === 'Rider&Delivery') {
      if (form_template?.includes('ce5ad6af-dc00-451e-9c3a-633e6a29cab7')) {
        window.location.href = 'https://ny.driversguild.org/services/'
        return
      }
      if (form_template?.includes('7a528a54-ec14-4c89-8718-5dcb85f8d20a')) {
        window.location.href = 'https://nj.driversguild.org/services/'
        return
      }
      if (form_template?.includes('74f0c7f8-2afe-40df-b588-1fa62b5dcdb8')) {
        window.location.href = 'https://ma.driversguild.org/services/'
        return
      }
      if (form_template?.includes('b2e70f1c-01b8-439f-adc1-31f1f5c0a47a')) {
        window.location.href = 'https://ct.driversguild.org/services/'
        return
      }
      if (form_template?.includes('3978ce97-7967-456b-9189-7f1f2fb0f324')) {
        window.location.href = 'https://il.driversguild.org/services/'
        return
      }
      window.location.href = 'https://driversguild.org'
      return
    }
  }
  // ct
  if (CONST_VALUES.form_template === 'b2e70f1c-01b8-439f-adc1-31f1f5c0a47a') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerImg}>
            <Letter />
          </div>
          <div className={cx(styles.basicItalicBoldHuge)}>
            {t('other.successThanks')}
          </div>
          <div
            className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
          >
            {t('other.successThanksSubtitle')}
          </div>
          {/* <div className={cx(styles.marginDouble, styles.basicComment)}>
            {t('other.successMessageCT')}

          </div>*/}

          <div className={cx(styles.marginDouble, styles.basicComment)}>
            <Trans
              t={t}
              i18nKey="other.successMessageCT"
              components={{
                ctPhone: <a href="tel:+1-833-240-7063" />,
                ctLink: (
                  <a href="https://ct.driversguild.org." target="_blank" />
                ),
              }}
            />
          </div>
          <div className={styles.containerButtons}>
            <Button onClick={handleOtherButton}>
              {isDeliveryApp
                ? t('other.deliveryWorkers')
                : t('other.useBenefits')}
            </Button>
            <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
          </div>
        </div>
      </div>
    )
  }
  // nj
  if (CONST_VALUES.form_template === '7a528a54-ec14-4c89-8718-5dcb85f8d20a') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerImg}>
            <Letter />
          </div>
          <div className={cx(styles.basicItalicBoldHuge)}>
            {t('other.successThanks')}
          </div>
          <div
            className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
          >
            {t('other.successThanksSubtitle')}
          </div>

          <div className={cx(styles.marginDouble, styles.basicComment)}>
            <Trans
              t={t}
              i18nKey="other.successMessageNJ"
              components={{
                njPhone: <a href="tel:+1-844-335-1264" />,
                njLink: (
                  <a href="https://nj.driversguild.org." target="_blank" />
                ),
              }}
            />
          </div>
          <div className={styles.containerButtons}>
            <Button onClick={handleOtherButton}>
              {isDeliveryApp
                ? t('other.deliveryWorkers')
                : t('other.useBenefits')}
            </Button>
            <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
          </div>
        </div>
      </div>
    )
  }

  // ma
  if (CONST_VALUES.form_template === '74f0c7f8-2afe-40df-b588-1fa62b5dcdb8') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerImg}>
            <Letter />
          </div>
          <div className={cx(styles.basicItalicBoldHuge)}>
            {t('other.successThanks')}
          </div>
          <div
            className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
          >
            {t('other.successThanksSubtitle')}
          </div>

          <div className={cx(styles.marginDouble, styles.basicComment)}>
            <Trans
              t={t}
              i18nKey="other.successMessageMA"
              components={{
                maPhone: <a href="tel:+1- 833-240-7062" />,
              }}
            />
          </div>
          <div className={styles.containerButtons}>
            <Button onClick={handleOtherButton}>
              {isDeliveryApp
                ? t('other.deliveryWorkers')
                : t('other.useBenefits')}
            </Button>
            <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
          </div>
        </div>
      </div>
    )
  }
  //il
  if (CONST_VALUES.form_template === '3978ce97-7967-456b-9189-7f1f2fb0f324') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerImg}>
            <Letter />
          </div>
          <div className={cx(styles.basicItalicBoldHuge)}>
            {t('other.successThanks')}
          </div>
          <div
            className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
          >
            {t('other.successThanksSubtitle')}
          </div>

          <div className={cx(styles.marginDouble, styles.basicComment)}>
            <Trans
              t={t}
              i18nKey="other.successMessageIL"
              components={{
                ilPhone: <a href="tel:+1-833-240-7064" />,
                ilLink: (
                  <a href="https://il.driversguild.org/" target="_blank" />
                ),
              }}
            />
          </div>
          <div className={styles.containerButtons}>
            <Button onClick={handleOtherButton}>
              {isDeliveryApp
                ? t('other.deliveryWorkers')
                : t('other.useBenefits')}
            </Button>
            <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
          </div>
        </div>
      </div>
    )
  }

  //fl

  if (CONST_VALUES.form_template === '29533d43-7dc1-4cb6-a744-df30265c75e9') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerImg}>
            <Letter />
          </div>
          <div className={cx(styles.basicItalicBoldHuge)}>
            {t('other.successThanks')}
          </div>
          <div
            className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
          >
            {t('other.successThanksSubtitle')}
          </div>

          <div className={cx(styles.marginDouble, styles.basicComment)}>
            <Trans
              t={t}
              i18nKey="other.successMessageFL"
              components={{
                flPhone: <a href="tel:+1-833-240-7064" />,
                flLink: (
                  <a href="https://fl.driversguild.org/" target="_blank" />
                ),
              }}
            />
          </div>
          <div className={styles.containerButtons}>
            <Button onClick={handleOtherButton}>
              {isDeliveryApp
                ? t('other.deliveryWorkers')
                : t('other.useBenefits')}
            </Button>
            <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.containerImg}>
          <Letter />
        </div>
        <div className={cx(styles.basicItalicBoldHuge)}>
          {t('other.successThanks')}
        </div>
        <div
          className={cx(styles.basicItalicBoldHugeSubtitle, styles.marginTen)}
        >
          {t('other.successThanksSubtitle')}
        </div>

        <div className={cx(styles.marginDouble, styles.basicComment)}>
          <Trans
            t={t}
            i18nKey="other.successMessageIL"
            components={{
              ilPhone: <a href="tel:+1-833-240-7064" />,
              ilLink: <a href="https://il.driversguild.org/" target="_blank" />,
            }}
          />
        </div>
        <div className={styles.containerButtons}>
          <Button onClick={handleOtherButton}>
            {isDeliveryApp
              ? t('other.deliveryWorkers')
              : t('other.useBenefits')}
          </Button>
          <Button onClick={handleContactUs}>{t('other.contactUs')}</Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessBlock
