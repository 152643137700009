import { APP_IS_TEMPORARILY_UNAVAILABLE } from 'src/config'

import { ISystemMessage } from './interface'

//TODO remove this file after all messages are moved to the database

// example:
//
// [
//     {
//         id: 'test',
//         message: {en: 'This is a test alert message'},
//         type: 'alert'
//     },
//     {
//         id: 'test2',
//         message: { en: 'This is a test alert message 2' },
//         type: 'alert'
//     },
//     {
//         id: 'exodus',
//         message: {en: 'Under maintenance from Jan 10th 8pm to Jan 11th 8am' },
//         type: 'blocking_message'
//     }
// ]

export const SYSTEM_MESSAGES: ISystemMessage[] = !APP_IS_TEMPORARILY_UNAVAILABLE
  ? []
  : [
      {
        id: 'exodus-blocking',
        message: {
          en: 'Our system is performing a scheduled update. We apologize for the inconvenience. If you need help, please email us at <a href="mailto:help@drivingguild.org">help@drivingguild.org</a>. <br/>Thank you',
          es: 'Nuestro sistema está realizando una actualización programada. Pedimos disculpas por las molestias. Si necesita ayuda, envíenos un correo electrónico a <a href="mailto:help@drivingguild.org">help@drivingguild.org</a>. <br/>Gracias',
        },
        type: 'blocking_message',
      },
    ]
