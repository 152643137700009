export default {
  label: {
    preferredLanguage: 'Choose Language',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    carCompanies: 'Which app(s) do you work on?',
    gender: 'Gender',
    phoneNumber: 'Phone Number',
    dateOfBirth: 'Date Of Birth',
    streetAddress: 'Street Address',
    apartment: 'Apt., unit, suit, or floor #',
    city: 'City',
    stateSelectHome: 'State',
    zipCode: 'Zip Code',
    driversLicenseNumber: "Driver's License #",
    driversLicenseNumberСonfirm: 'Re-Enter DL #',
    driversId: 'ID #',
    driversIdСonfirm: 'Re-Enter ID #',
    tlcLicenseNumber: 'TLC #',
    tlcLicenseNumberСonfirm: 'Re-Enter TLC #',
    howYouDeliver: 'How you deliver',
    whatStateDoYouDriveIn: 'What state do you drive in',
  },
  placeholder: {
    email: 'Enter Your Email',
    firstName: 'Enter Your Name',
    lastName: 'Enter Your Last Name',
    gender: 'Select Gender',
    phoneNumber: '000-000-0000',
    streetAddress: 'Enter Your Street Address',
    apartment: 'Enter #',
    city: 'Enter City',
    stateSelectHome: 'Select State',
    zipCode: 'Enter Your Zip Code',
    driversLicenseNumber: "Enter Your Driver's License",
    driversLicenseNumberСonfirm: 'Re-Enter DL #',
    driversId: 'Enter Your ID',
    driversIdСonfirm: 'Re-Enter ID #',
    tlcLicenseNumber: 'Enter Your TLC #',
    tlcLicenseNumberСonfirm: 'Re-Enter TLC #',
    other: 'Other',
  },
  options: {
    preferredLanguage: {
      en: 'English',
      es: 'Español',
      chinese: '中国人',
      Fre: 'Français',
      ru: 'Русский',
      pa: 'ਪੰਜਾਬੀ',
      Hindi: 'हिन्दी',
      bn: 'বাংলা',
      ur: 'اردو',
      Arabic: 'عربي',
    },
    carCompanies: {
      uber: 'Uber',
      uberEats: 'Uber Eats',
      lyft: 'Lyft',
      doordash: 'Doordash',
      grubhub: 'Grubhub',
      postmates: 'Postmates',
      instacart: 'Instacart',
      seamless: 'Seamless',
    },
    gender: {
      Male: 'Male',
      Female: 'Female',
      'Prefer not to say': 'Prefer not to say',
    },
    state: {
      'New York': 'New York',
      'New Jersey': 'New Jersey',
      Connecticut: 'Connecticut',
      Pennsylvania: 'Pennsylvania',
      Alabama: 'Alabama',
      Alaska: 'Alaska',
      Arizona: 'Arizona',
      Arkansas: 'Arkansas',
      California: 'California',
      Connecticut: 'Connecticut',
      Colorado: 'Colorado',
      Delaware: 'Delaware',
      'District Of Columbia': 'District Of Columbia',
      Florida: 'Florida',
      Georgia: 'Georgia',
      Hawaii: 'Hawaii',
      Idaho: 'Idaho',
      Illinois: 'Illinois',
      Indiana: 'Indiana',
      Iowa: 'Iowa',
      Kansas: 'Kansas',
      Kentucky: 'Kentucky',
      Louisiana: 'Louisiana',
      Maine: 'Maine',
      Maryland: 'Maryland',
      Massachusetts: 'Massachusetts',
      Michigan: 'Michigan',
      Minnesota: 'Minnesota',
      Mississippi: 'Mississippi',
      Missouri: 'Missouri',
      Montana: 'Montana',
      Nebraska: 'Nebraska',
      Nevada: 'Nevada',
      'New Hampshire': 'New Hampshire',
      'New Mexico': 'New Mexico',
      'North Carolina': 'North Carolina',
      'North Dakota': 'North Dakota',
      Ohio: 'Ohio',
      Oklahoma: 'Oklahoma',
      Oregon: 'Oregon',
      'Rhode Island': 'Rhode Island',
      'South Carolina': 'South Carolina',
      'South Dakota': 'South Dakota',
      Tennessee: 'Tennessee',
      Texas: 'Texas',
      Utah: 'Utah',
      Vermont: 'Vermont',
      Virginia: 'Virginia',
      Washington: 'Washington',
      'West Virginia': 'West Virginia',
      Wisconsin: 'Wisconsin',
      Wyoming: 'Wyoming',
    },
    howYouDeliver: { car: 'Car', bike: 'Bike', onFoot: 'On Foot' },
  },
  button: { nextStep: 'Next Step', submit: 'Submit' },
  error: {
    required: 'Must be present',
    email: 'Must be email',
    phone: 'Invalid phone number',
    date: 'Invalid date',
    driverAge: { dateOfBirth: 'Must be between 16 and 100 years old' },
    name: 'Must be a valid name',
    streetAddress: 'Must be a valid street address',
    city: 'Must be a valid city',
    zipCode: 'Must be a valid zip code',
    driverLicense: 'Must be a valid driver license',
    atLeast1: 'Select at least one',
    equalTo: {
      driversLicenseNumberСonfirm: 'Driver License must match',
      driversIdСonfirm: 'ID must match',
      tlcLicenseNumberСonfirm: 'TLC must match',
    },
  },
  other: {
    step: 'Step',
    oneMoreStep: 'One more step',
    toAccessTheBenefits: 'to access the benefits',
    IDGIsHereForYou:
      'IDG is here for you! Join us to fight for app worker rights! \nNote that there are no benefits for delivery workers yet.',
    byCompletingTheInformation:
      'By completing the information above and clicking “Submit”, you confirm that you have read, understand, and agree to be bound by our <termsOfUseLink>Terms of Use</termsOfUseLink>. Also, you agree that we may use and disclose your information as set forth in our <privacyPolicyLink>Privacy Policy</privacyPolicyLink>',
    goodJob: 'Good job!',
    submitAndGetBenefits: 'Submit the form to get access to the benefits!',
    congratulations: 'Congratulations',
    weWillBeInTouchSoon: 'We will be in touch soon!',
    successThanks: 'Thank you for joining us!',
    successThanksSubtitle: 'We’re processing your application',
    /* successMessage: 'We have received your information. We will be in touch soon!',*/

    successMessageCT:
      'You’ve taken the first step towards joining the Connecticut community of drivers. It’s with your support that we can continue to fight for a fair industry with improved working conditions. \n' +
      'We’ll send you an email with more details about your new IDG membership, and how you can stay active in our community. <br> <br>' +
      '\n' +
      'If you don’t see it in your inbox within the next few days, please make sure to check your spam folder. <br> <br>' +
      '\n' +
      'Want to gain access immediately or have questions? Call us at <ctPhone>833-240-7063</ctPhone>, M-F 8am - 8pm ET or find our office hours on <ctLink>ct.driversguild.org.</ctLink>',

    successMessageNJ:
      'You’ve taken the first step towards joining the New Jersey community of drivers. It’s with your support that we can continue to fight for a fair industry with improved working conditions. Please note we will need to verify your membership by confirming you are an active driver in NJ.<br> <br> ' +
      '\n' +
      'If you don’t see it in your inbox within the next few days, please make sure to check your spam folder. <br><br> Want to gain access immediately or have questions? Call us at <njPhone>1-844-335-1264</njPhone>, M-F 8am - 8pm ET or find our office hours on <njLink>nj.driversguild.org.</njLink>',

    successMessageMA:
      'You’ve taken the first step towards joining the Massachusetts community of drivers. It’s with your support that we can continue to fight for a fair industry with improved working conditions. \n' +
      'We’ll send you an email with more details about your new IDG membership, and how you can stay active in our community. <br><br> If you don’t see it in your inbox within the next few days, please make sure to check your spam folder. <br><br> Want to gain access immediately or have questions?  Call us at <maPhone>1- 833-240-7062</maPhone>, M-F 8am - 8pm ET.',

    successMessageIL:
      'You’ve taken the first step towards joining the Illinois community of drivers. It’s with your support that we can continue to fight for a fair industry with improved working conditions. <br><br> We’ll send you an email with more details about your new IDG membership, and how you can stay active in our community. <br><br> If you don’t see it in your inbox within the next few days, please make sure to check your spam folder. <br> <br> Questions? Call us at <ilPhone>1-833-240-7064</ilPhone> or check out our office hours and upcoming events on <ilLink>il.driversguild.org.</ilLink>',

    successMessageFL:
      'You’ve taken the first step towards joining the Florida community of drivers. It’s with your support that we can continue to fight for a fair industry with improved working conditions. <br><br> We’ll send you an email with more details about your new IDG membership, and how you can stay active in our community.  <br><br> If you don’t see it in your inbox within the next few days, please make sure to check your spam folder. <br><br> Questions? Call us at <flPhone>1-833-240-7064</flPhone> or check out our office hours and upcoming events on <flLink>fl.driversguild.org</flLink>.',
    useBenefits: 'Use your Benefits',
    contactUs: 'Contact Us',
    deliveryWorkers: 'Delivery Workers',
  },
  systemMessages: {
    blocking: {
      title: 'Temporarily Unavailable',
    },
  },
}
