import { useMutation } from 'react-query'
import { api } from 'src/api'

import { ILeadMutationData } from 'src/interfaces'

const useSendFormData = (action: 'create' | 'update') =>
  useMutation(
    async (data: ILeadMutationData) => api['put']('/v1/forms', data),
    {
      onSuccess: (result) => {
        if (result && result.data) {
          console.log('USE MUTATE LEAD RESULT')
          console.log(result.data)
        }
      },
    },
  )

export default useSendFormData
