import Tabs from './Tabs'
import Footer from './Footer'
import SuccessBlock from './SuccessBlock'
import SystemMessages from './SystemMessages'

import styles from './basic-template.module.scss'

interface BasicTemplateProps {
  loading: boolean
  stepsCount: number
  currentStepIndex: number
  isSuccess: boolean
  onNext: () => void
}

const BasicTemplate = (props: React.PropsWithChildren<BasicTemplateProps>) => {
  const { loading, stepsCount, currentStepIndex, isSuccess, onNext, children } =
    props

  return (
    <div className={styles.wrapper}>
      {!isSuccess ? (
        <Tabs stepsCount={stepsCount} currentStepIndex={currentStepIndex} />
      ) : null}

      <SystemMessages />

      <div className={styles.inner}>
        {!isSuccess ? (
          <>
            {children}

            <Footer
              loading={loading}
              stepsCount={stepsCount}
              currentStepIndex={currentStepIndex}
              onNext={onNext}
            />
          </>
        ) : (
          <SuccessBlock />
        )}
      </div>
    </div>
  )
}

export default BasicTemplate
